import { http } from '../utils/http';
const path = process.env.VUE_APP_PATH + '/driver-center';

// 运单列表
export const getBillSearch = async data => {
  return await http['post'](`${path}/driver/transport/bill/search`, data, false);
};

// 运单详情
export const getBillDetail = async data => {
  return await http['post'](`${path}/driver/transport/bill/detail`, data, false);
};

// 评价
export const getBillComment = async data => {
  return await http['post'](`${path}/driver/transport/bill/comment`, data, false);
};

// 评价
export const billLoading = async data => {
  return await http['post'](`${path}/driver/transport/bill/loading`, data, false);
};
// 评价
export const billUnloading = async data => {
  return await http['post'](`${path}/driver/transport/bill/Unloading`, data, false);
};

// 取消接单
export const billCloseApi = async data => {
  return await http['post'](`${path}/driver/transport/bill/close`, data, false);
};

// 司机装卸货是否自动填充货重
export const automaticFillApi = async data => {
  return await http['post'](`${path}/driver/mine/config/getDriverWaybillAutomaticFill`, data, false);
};

// 获取卡车的载重量
export const getTruckInfoApi = async ({ id }) => {
  return await http['post'](`/api/resource/truck/getTruckInfo`, { id }, false);
};

export const getTruckRelateApi = async ({ carLicensePlate }) => {
  return await http['post'](
    `/api/resource/truck/billGetTruckInfo`,
    { carLicensePlate, pageNum: 1, pageSize: 20 },
    false
  );
};

// 获取公司配置装货重量浮动值配置
export const getTransportWeightFloatApi = async data => {
  return await http['post'](`/api/resource/companyConfig/getTransportWeightFloat`, data, false);
};

// 获取公司配置装货重量浮动值配置
export const getUnloadWeightFloatApi = async data => {
  return await http['post'](`/api/resource/companyConfig/getTransportUnloadingWeightFloat`, data, false);
};

// 签订共享协议
export const signShareContractAPi = async ({ driverId }) => {
  return await http['post'](`/api/resource/app/contract/carrier/signShareContract`, { driverId }, false);
};

//查询平台配置
export const getPlatformConfigApi = async data => {
  return await http['post'](`/api/resource/app/contract/carrier/platformConfig`, data, false);
};
