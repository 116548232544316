<template>
  <div class="pb-48 agreements pa-16">
    <Navbar :title="pageTitle" v-if="!outSide" customClickLeft @clickLeft="handleClickLeft" />
    <div class="isHtmlStr" v-html="htmlContent" v-if="!isPdf"></div>
    <div id="pdf"></div>

    <van-button
      v-if="isAgentContract"
      type="primary"
      size="medium"
      class="fixed-footer-btn"
      @click="signAgentContract"
      :loading="agentBtnLoading"
      :disabled="agentBtnDisabled"
    >
      签约
    </van-button>
    <div v-if="!!showSignBtn" class="fixed-footer-btn bg-white h-40">
      <van-button type="primary" size="medium" class="fixed-footer-btn" @click="showSign" :disabled="disabledSignBtn"
        >我已阅读并同意合同内容{{ timeoutSec > 0 ? `(${timeoutSec})` : '' }}</van-button
      >
    </div>
    <van-popup v-model="popupVisiable" position="bottom" round>
      <div>
        <div class="pt-12 pb-10 w-full flex items-center justify-center h-40 text-main font-500">
          请签署本人姓名
        </div>
        <div class="pb-64 flex justify-center relative" style="padding-left: 20px; padding-right: 20px">
          <SignCanvas
            ref="SignCanvas"
            :options="options"
            style="border: 1px dashed #999; border-radius: 8px;z-index: 2"
          ></SignCanvas>
          <div
            class="absolute text-center text-20 w-full"
            style="top: 76px;letter-spacing: 20px;color: #E0E0E0;user-select: none;height: 0px;z-index: 1"
          >
            手写签名
          </div>
          <span class="text-primary absolute" style="bottom: 76px;right: 30px;z-index: 2" @click="clearCanvas"
            >清除</span
          >
        </div>
        <van-button type="primary" size="medium" class="fixed-footer-btn" :loading="busyLoading" @click="signContract">
          确认签署
        </van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useRouterQuery } from '@hook/useRouterQuery';
import { apiUnsignDetail, apiConDetail, entrustSignMsmAgreeApi } from '@api/mine';
import { useUserStore } from '@/store/user';
import { Toast, Dialog } from 'vant';
import UA from '@/client/ua';
import ClinentApp from '@/client/index';
import { useRouter } from '@/utils/compsitionHack';
import { ENTRUST_KEY } from './constants';
import { PageEnum } from '@/enums/pageEnum';
import SignCanvas from 'sign-canvas';
import { uploadFile } from '@/api/file';
import { apiSignConstruct } from '@api/mine';
import useGetDifferenceInfo from '../driver/waybill/goodsInfo/hooks/useGetDifferenceInfo';
import { useWaybillStore } from '@/store/waybill';
import ClientApp from '@/client/index';
export default defineComponent({
  name: 'Agreement',
  components: { SignCanvas },
  setup(_, ctx) {
    const query = useRouterQuery();
    const isiOS = ref(!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/));
    const htmlContent = ref('');
    const isPdf = ref(''); // pdf连接
    const outSide = ref(false); // 是否为外部显示连接
    const pageTitle = ref('合同详情');
    const popupVisiable = ref(false);
    const userStore = useUserStore();
    const timeoutSec = ref(10);
    const interval = ref();
    const disabledSignBtn = ref(true);
    const showSignBtn = query.showSign;
    const waybillStore = useWaybillStore();
    const busyLoading = ref(false);
    onMounted(async () => {
      if (query.typeAgreement) {
        pageTitle.value = +query.typeAgreement === 1 ? '用户服务协议' : '隐私政策';
        htmlContent.value = query.typeAgreement === 1 ? '1' : '2';
      } else {
        if (query.batchNo || query.type === '3') {
          outSide.value = true;
          try {
            const res = await apiConDetail({
              ...query,
              driverId: userStore?.driverId || null
            });
            if (res) {
              htmlContent.value = res.data.attachment;
              if (res.data.attachment.split('')[0] !== '<') {
                isPdf.value = true;
                if (isiOS.value) {
                  Dialog.confirm({
                    message: '是否打开PDF文件？'
                  })
                    .then(() => {
                      window.open(`${window.location.origin}/pdf.html?file=${res.data.attachment}`);
                    })
                    .catch(() => {});
                } else {
                  window.open(`${window.location.origin}/pdf.html?file=${res.data.attachment}`);
                }
              }
            }
          } catch (err) {
            Toast.fail(err);
          } finally {
            Toast.clear();
          }
        } else {
          try {
            const res = await apiUnsignDetail({
              ...query,
              companyId: +query.companyId,
              type: +query.type,
              driverId: userStore?.driverId,
              goods: query.weight
                ? {
                    goodsWeightText: query.weight, //重量
                    goodsVolumeText: query.volume, //体积
                    goodsNumberText: query.number // 件数
                  }
                : undefined
            });
            if (res) {
              htmlContent.value = res.data;
            }
          } catch (err) {
            Toast.fail(err);
          } finally {
            Toast.clear();
          }
        }
      }
      interval.value = setInterval(() => {
        if (timeoutSec.value > 0) {
          timeoutSec.value = timeoutSec.value - 1;
          if (timeoutSec.value === 0) {
            disabledSignBtn.value = false;
          }
        } else {
          clearInterval(interval.value);
        }
      }, 1000);
    });

    const router = useRouter();
    const handleClickLeft = () => {
      if (UA.androidClient && !showSignBtn) {
        ClinentApp.finish();
      } else {
        router.back();
      }
    };

    const setContractFlag = () => {
      window.localStorage.setItem(`${ENTRUST_KEY}${query.contractId}`, 1);
    };

    const canSignContract = () => {
      return window.localStorage.getItem(`${ENTRUST_KEY}${query.contractId}`) ?? false;
    };

    // 如果是委托代收协议，需要显示签约按钮
    const agentBtnLoading = ref(false);
    const agentBtnDisabled = ref(false);
    const isAgentContract = query.type === '3';

    const hasSigned = canSignContract();
    agentBtnDisabled.value = hasSigned ? true : false;
    if (hasSigned) {
      agentBtnDisabled.value = true;
    } else {
      agentBtnDisabled.value = false;
    }
    const signAgentContract = async () => {
      try {
        agentBtnLoading.value = true;
        const res = await entrustSignMsmAgreeApi({
          contractId: query.contractId,
          t: query.t,
          u: query.u,
          type: query.type
        });

        if (res) {
          setContractFlag();
          agentBtnDisabled.value = true;
          Toast('签约成功');
        } else {
          // 404 走这里？？？
          agentBtnLoading.value = false;
          agentBtnDisabled.value = false;
        }
      } catch (error) {
        Toast.fail(error);
        agentBtnDisabled.value = false;
      } finally {
        agentBtnLoading.value = false;
      }
    };

    const showSign = () => {
      popupVisiable.value = true;
    };

    const closeSign = () => {
      ctx.refs.SignCanvas.canvasClear();
      popupVisiable.value = false;
    };

    const options = {
      lastWriteSpeed: 1,
      lastWriteWidth: 1,
      imgType: 'png',
      writeColor: '#000',
      maxWriteWidth: 4,
      minWriteWidth: 2,
      writeWidth: 2,
      borderColor: '#999',
      isShowBorder: false,
      canvasWidth: window.innerWidth - 40,
      canvasHeight: (window.innerWidth - 40) / 2,
      lineCap: 'round',
      lineJoin: 'round',
      isSign: true,
      bgColor: null
    };

    const signContract = async () => {
      try {
        busyLoading.value = true;
        const file = dataURLtoFile();
        const resFile = await uploadFile(file);
        const { data } = resFile;
        const res = await apiSignConstruct({
          driverId: userStore.driverId,
          companyId: query.companyId,
          carrierSealUrl: data.fileName,
          transportBillNumber: query.transportBillNumber
        });
        if (res) {
          Toast.success('签约成功！');
          closeSign();
          const { typeText, submitApiName } = useGetDifferenceInfo(+query.loadType);
          const params = waybillStore.loadParams;
          await submitApiName(params);
          Toast(`${typeText}成功`);
          ClientApp.jumpNativePage(0, 0);
          // .then(async () => {
          //   try {
          //     await syncLocation(params);
          //   } catch (error) {
          //     // alert('回调失败');
          //     console.log(error);
          //   } finally {
          //     Toast(`${typeText}成功`);
          //   }
          // })
          // .catch(() => {
          //   busy.value = false;
          //   loading.value = false;
          // });
          // router.push(PageEnum.CONTRACT_LIST);
          // }
        }
      } catch (e) {
        //
        busyLoading.value = false;
      } finally {
        busyLoading.value = false;
      }
    };

    const dataURLtoFile = () => {
      const img = new Image();
      img.src = ctx.refs.SignCanvas.$refs[ctx.refs.SignCanvas.domId].toDataURL(`image/png`);

      // 设置导出图片的大小
      // const canvas = document.createElement('canvas');
      // canvas.height = 79;
      // canvas.width = 159;

      // const ct = canvas.getContext('2d');
      // ct.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

      // const newRes = canvas.toDataURL(`image/png`);
      const base64Str = img.src;
      const arr = base64Str.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], 'sign.png', { type: mime });
    };

    const clearCanvas = () => {
      ctx.refs.SignCanvas.canvasClear();
    };

    return {
      pageTitle,
      isPdf,
      outSide,
      htmlContent,
      handleClickLeft,

      isAgentContract,
      agentBtnLoading,
      agentBtnDisabled,
      signAgentContract,

      showSign,
      signContract,
      options,
      popupVisiable,
      disabledSignBtn,
      timeoutSec,
      userStore,
      clearCanvas,
      showSignBtn,
      busyLoading
    };
  }
});
</script>
<style lang="less">
iframe {
  width: 100vw;
  height: 100vh;
}
.con {
  padding-bottom: 0.24rem;
}

.fixed-footer-btn {
  position: fixed;
  bottom: 12px;
  width: 92%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
