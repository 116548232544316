/**
 * @param {Number} type - 0: 装货； 1: 卸货
 */

import { billLoading, billUnloading } from '@api/waybill';
export default type => {
  let differenceInfo;
  if (type) {
    differenceInfo = { typeText: '卸货', submitApiName: billUnloading };
  } else {
    differenceInfo = { typeText: '装货', submitApiName: billLoading };
  }
  return differenceInfo;
};
