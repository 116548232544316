import { defineStore } from 'pinia';

export const useWaybillStore = defineStore({
  id: 'waybill',
  state: () => ({
    contractList: [], // 装货合同
    loadParams: {}
  }),
  getters: {
    // contractList() {
    //   return this.contractList;
    // }
  },
  actions: {
    saveContractList(contractList) {
      this.contractList = contractList;
    },
    saveLoadParams(loadParams) {
      this.loadParams = loadParams;
    }
  }
});
